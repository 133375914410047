var divisor = document.querySelectorAll('.js-comparison-divisor'),
    handle = document.querySelectorAll('.js-comparison-handle'),
    sliders = document.querySelectorAll('.js-comparison-slider');

window.onload = function () {
    _sliders(sliders);
};

function _sliders(items) {
    if (!items) {
        return;
    }

    items.forEach((item, index) => {
        item.addEventListener(
            'input',
            event => {
                event.preventDefault();
                moveDivisor(item, index);
            },
            false
        );
    });
}

function moveDivisor(slider, index) {
    handle[index].style.left = slider.value + '%';
    divisor[index].style.width = slider.value + '%';
}
